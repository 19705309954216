<template>
  <div class="refund_content">
    <SearchPanel
      flex
      show-btn
      size="mini"
      @getList="getSearchList"
    >
      <div>
        <p>买家昵称</p>
        <el-input
          v-model="searchData.buyerNick"
          size="mini"
          placeholder="请输入买家昵称"
          clearable
        ></el-input>
      </div>
      <div>
        <p>申请单类型</p>
        <el-select
          v-model="searchData.applyUserType"
          placeholder="请选择"
          size="mini"
          filterable
          clearable
        >
          <el-option
            v-for="item in Object.keys(APPLY_ORDER_TYPE)"
            :key="item"
            :label="APPLY_ORDER_TYPE[item]"
            :value="item"
          />
        </el-select>
      </div>
      <div>
        <p>订单编号</p>
        <el-input
          v-model="searchData.orderNo"
          size="mini"
          placeholder="请输入订单编号"
          clearable
        ></el-input>
      </div>
      <div>
        <p>申请单号</p>
        <el-input
          v-model="searchData.applyNo"
          size="mini"
          placeholder="请输入申请单号"
          clearable
        ></el-input>
      </div>
      <div>
        <p>申请单状态</p>
        <el-select
          v-model="searchData.reviewStatus"
          placeholder="请选择"
          size="mini"
          filterable
          clearable
        >
          <el-option
            v-for="item in Object.keys(ORDER_STATUS)"
            :key="item"
            :label="ORDER_STATUS[item]"
            :value="item"
          />
        </el-select>
      </div>
      <div>
            <p>
              申请时间
            </p>
            <el-date-picker
              v-model="dateRangeApply"
              type="datetimerange"
              size="mini"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
      </div>
      <div>
            <p>
              处理时间
            </p>
            <el-date-picker
              v-model="dateRangeHandle"
              type="datetimerange"
              size="mini"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="resetSearch"
      >
        重 置
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="tableData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
    >
      <el-table-column
        prop="indexNumber"
        label="序号"
        width="50px"
      />
      <el-table-column
        label="买家昵称"
        width="98px"
      >
        <template  slot-scope="{row}">
          <span>{{ row.buyerNick }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="applyUserType"
        label="申请单类型"
        width="92px"
      >
        <template slot-scope="{row}">
          <span>{{ row.applyUserType == '1'? '买家修改': '卖家修改' }} </span>
        </template>
      </el-table-column>
      <el-table-column
        label="订单编号"
      >
      <template  slot-scope="{row}">
          <span>{{ row.orderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="applyNo"
        label="申请单号"
        min-width="102"
      >
        <template slot-scope="{row}">
          <div>{{ row.applyNo }}</div>
          <div>申请人：{{ row.applyUserName }}</div>
          <div>申请时间：{{ dateFormat(+row.createTime, 'yyyy-MM-dd hh:mm:ss') }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="原地址"
        min-width="120"
      >
        <template slot-scope="{row}">
          <span>{{ row.oldReceiveInfo.receiverNameSecrecy || '-' }}， </span>
          <span>{{ row.oldReceiveInfo.receiverMobileSecrecy || '-' }}</span>
          <div>
            <span>{{ row.oldReceiveInfo.provinceName }}</span>
            <span>{{ row.oldReceiveInfo.cityName }}</span>
            <span>{{ row.oldReceiveInfo.districtName }}</span>
            <span>{{ row.oldReceiveInfo.townName }}</span>
            <span>{{ row.oldReceiveInfo.address }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="新地址"
        min-width="120"
      >
      <template slot-scope="{row}">
          <span>{{ row.newReceiveInfo.receiverNameSecrecy || '-'  }}， </span>
          <span>{{ row.newReceiveInfo.receiverMobileSecrecy || '-'  }}</span>
          <div>
            <span>{{ row.newReceiveInfo.provinceName }}</span>
            <span>{{ row.newReceiveInfo.cityName }}</span>
            <span>{{ row.newReceiveInfo.districtName }}</span>
            <span>{{ row.newReceiveInfo.townName }}</span>
            <span>{{ row.newReceiveInfo.address }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="申请单状态"
        min-width="106"
      >
        <template slot-scope="{row}">
          <div v-if="row.reviewStatus == 0">
            待处理
          </div>
          <div v-else>
            <div class="success" v-if="row.reviewStatus === 1">已同意</div>
            <div class="fail" v-if="row.reviewStatus === 2">已拒绝</div>
            <div>处理人: {{ row.reviewUserName }}</div>
            <div>处理时间: {{ dateFormat(+row.reviewTime, 'yyyy-MM-dd hh:mm:ss') }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="70"
      >
        <template slot-scope="{row}">
          <el-button
            v-if="row.reviewStatus == 0"
            type="text"
            size="mini"
            @click="handleChangeAddress(row)"
          >
          处理
          </el-button>
          <el-button
            v-else
            type="text"
            size="mini"
            @click="handleLookAddress(row)"
          >
          查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData && tableData.length > 0"
      small
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @refreshList="getList"
    />
    <change-address ref="ChangeAddressRef" @refreshList="getList"/>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {dateFormat} from '@/utils/utils'
import { isApp } from '../../../../utils/isApp.js'
import {
  APPLY_ORDER_TYPE,
  ORDER_STATUS,
} from '../normals';
import ChangeAddress from './changeAddressDialog.vue';
import Moment from 'moment';

export default {
  name: 'RefundList',
  components: {
      ChangeAddress,
  },
  data() {
    return {
      APPLY_ORDER_TYPE,
      ORDER_STATUS,
      loading: false,
      searchData: {
        buyerNick: undefined, // 买家昵称
        orderNo: undefined, // 订单编号
        applyUserType: undefined, // 申请单类型 1：用户，2：商家
        applyNo: undefined, // 申请单号
        reviewStatus: undefined, // 申请单状态 0：待处理，1：同意，2：拒绝
      },
      dateRangeApply: [Moment().subtract(3, 'month').format("YYYY-MM-DD") + ' 00:00:00', Moment().format("YYYY-MM-DD") + ' 23:59:59'],
      dateRangeHandle: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      count: 0,
      choiceDate: null,
      pickerOptions: {},
    }
  },
  mounted() {
    const from = isApp()
    let navbar = document.getElementById('navbar')
    if (from && navbar) {
      navbar.style.opacity = '0'
    } else {
      navbar.style.opacity = '1'
    }
    // 从订单列表跳过来
    if (this.$route.query.orderNo) {
      this.searchData.orderNo = this.$route.query.orderNo
      this.searchData.reviewStatus = '0'
      this.dateRangeApply = []
      this.getList()
    }else{
      this.getList()
    }
  },
  methods: {
    dateFormat,
    getSearchList() {
      this.currentPage = 1;
      this.isSearch = true
      this.getList()
    },
    resetSearch() {
      this.currentPage = 1;
      this.searchData = this.$options.data().searchData;
      this.dateRangeApply = [Moment().subtract(3, 'month').format("YYYY-MM-DD") + ' 00:00:00', Moment().format("YYYY-MM-DD") + ' 23:59:59'];
      this.dateRangeHandle = [];
      this.getList();
      this.$router.push({ 
        path: '/nb/order/address'
      })
    },
    getList() {
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        applyBeginTime: this.dateRangeApply? this.dateRangeApply[0]: undefined,
        applyEndTime: this.dateRangeApply? this.dateRangeApply[1]: undefined,
        reviewBeginTime: this.dateRangeHandle? this.dateRangeHandle[0]: undefined,
        reviewEndTime: this.dateRangeHandle? this.dateRangeHandle[1]: undefined,
        buyerNick: this.searchData.buyerNick || undefined, // 买家昵称
        orderNo: this.searchData.orderNo || undefined, // 订单编号
        applyUserType: this.searchData.applyUserType || undefined, // 申请单类型 -1：全部，1：用户，2：商家
        applyNo: this.searchData.applyNo || undefined, // 申请单号
        reviewStatus: this.searchData.reviewStatus || undefined, // 申请单状态 -1：全部，0：待处理，1：同意，2：拒绝
      }
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$api.order.modifyReceivePage,
        data: params,
      }).then(res => {
        this.loading = false
        const r = res || {}
        if(r.code !== 0) return;
        if(!r.data) return;
        this.tableData = Array.isArray(r.data.records)? r.data.records: []
        this.count = Number(r.data.total)
        this.handleTableData(r.data)
      })
    },
    // 脱敏
    handleTableData(param) {
      this.tableData.forEach((ele, index)=>{
        let newName = ele.newReceiveInfo.receiverName;
        let newMobile = ele.newReceiveInfo.receiverMobile;
        let oldName = ele.oldReceiveInfo.receiverName;
        let oldMobile = ele.oldReceiveInfo.receiverMobile;
        ele.newReceiveInfo.receiverNameSecrecy = newName? newName.substr(0,1) + Array(newName.length-1).fill('*').join(''): '';
        ele.oldReceiveInfo.receiverNameSecrecy = oldName? oldName.substr(0,1) + Array(oldName.length-1).fill('*').join(''): '';
        ele.newReceiveInfo.receiverMobileSecrecy = newMobile? newMobile.substr(0,3) + '****' + newMobile.substr(7): ''
        ele.oldReceiveInfo.receiverMobileSecrecy = oldMobile? oldMobile.substr(0,3) + '****' + oldMobile.substr(7): ''
        ele.indexNumber = (param.current - 1) * param.size + index + 1;
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.isSearch = false
      this.getList()
    },
    formatDate(time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
    },
    handleChangeAddress(item) {
      this.$refs.ChangeAddressRef.openHandle(item);
    },
    handleLookAddress(item) {
      this.$refs.ChangeAddressRef.openLook(item);
    },
  }
}
</script>

<style lang="scss" scoped>
.refund_content {
  background: #fff;
  padding: 10px;
}
.success {
  color: #599C56;
}
.fail {
  color: #E81212;
}
</style>
