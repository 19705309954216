var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        size: "tiny",
        width: "550px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "100px",
            rules: _vm.rules,
            model: _vm.ruleForm,
          },
        },
        [
          _c("el-form-item", { attrs: { label: "订单编号：" } }, [
            _c("span", [_vm._v(_vm._s(_vm.ruleForm.orderNo))]),
          ]),
          _c("el-form-item", { attrs: { label: "原地址：" } }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.ruleForm.oldReceiveInfo.receiverNameSecrecy || "-") +
                  "， "
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.ruleForm.oldReceiveInfo.receiverMobileSecrecy || "-")
              ),
            ]),
            _c("div", [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.oldReceiveInfo.provinceName)),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.oldReceiveInfo.cityName)),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.oldReceiveInfo.districtName)),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.oldReceiveInfo.townName)),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.ruleForm.oldReceiveInfo.address))]),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "新地址：" } }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.ruleForm.newReceiveInfo.receiverNameSecrecy || "-") +
                  "， "
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.ruleForm.newReceiveInfo.receiverMobileSecrecy || "-")
              ),
            ]),
            _c("div", [
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.newReceiveInfo.provinceName)),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.newReceiveInfo.cityName)),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.newReceiveInfo.districtName)),
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm.ruleForm.newReceiveInfo.townName)),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.ruleForm.newReceiveInfo.address))]),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "处理结果：" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.ruleForm.reviewStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "reviewStatus", $$v)
                    },
                    expression: "ruleForm.reviewStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("同意")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("拒绝")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.reviewStatus == 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "原因：",
                    required: "",
                    prop: "refuseReason",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        size: "mini",
                        filterable: "",
                        clearable: "",
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.ruleForm.refuseReason,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "refuseReason", $$v)
                        },
                        expression: "ruleForm.refuseReason",
                      },
                    },
                    _vm._l(_vm.rejectList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.type === "edit"
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }