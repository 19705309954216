<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    size="tiny"
    width="550px"
    :append-to-body="true"
    @close="close"
  >
    <el-form
      ref="ruleForm"
      label-width="100px"
      :rules="rules"
      :model="ruleForm"
    >
      <el-form-item
        label="订单编号："
      >
        <span>{{ ruleForm.orderNo }}</span>
      </el-form-item>
      <el-form-item
        label="原地址："
      >
          <span>{{ ruleForm.oldReceiveInfo.receiverNameSecrecy || '-' }}， </span>
          <span>{{ ruleForm.oldReceiveInfo.receiverMobileSecrecy || '-' }}</span>
          <div>
            <span>{{ ruleForm.oldReceiveInfo.provinceName }}</span>
            <span>{{ ruleForm.oldReceiveInfo.cityName }}</span>
            <span>{{ ruleForm.oldReceiveInfo.districtName }}</span>
            <span>{{ ruleForm.oldReceiveInfo.townName }}</span>
            <span>{{ ruleForm.oldReceiveInfo.address }}</span>
          </div>
      </el-form-item>
      <el-form-item
        label="新地址："
      >
          <span>{{ ruleForm.newReceiveInfo.receiverNameSecrecy || '-'  }}， </span>
          <span>{{ ruleForm.newReceiveInfo.receiverMobileSecrecy || '-'  }}</span>
          <div>
            <span>{{ ruleForm.newReceiveInfo.provinceName }}</span>
            <span>{{ ruleForm.newReceiveInfo.cityName }}</span>
            <span>{{ ruleForm.newReceiveInfo.districtName }}</span>
            <span>{{ ruleForm.newReceiveInfo.townName }}</span>
            <span>{{ ruleForm.newReceiveInfo.address }}</span>
          </div>
      </el-form-item>
      <el-form-item
        label="处理结果："
      >
      <el-radio-group v-model="ruleForm.reviewStatus" :disabled="disabled">
        <el-radio :label="1">同意</el-radio>
        <el-radio :label="2">拒绝</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.reviewStatus == 2"
        label="原因："
        required
        prop="refuseReason"
      >
      <el-select
          v-model="ruleForm.refuseReason"
          placeholder="请选择"
          size="mini"
          filterable
          clearable
          :disabled="disabled"
        >
          <el-option
            v-for="item in rejectList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
      v-if="type === 'edit'"
    >
      <el-button @click="close">取 消</el-button>
      <el-button
        type="primary"
        @click="handleConfirm('ruleForm')"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ChangeAddress',
  props: {},
  data() {
    return {
      rejectList: [],
      visible: false,
      ruleForm: {
        orderNo: '',
        oldReceiveInfo: '',
        newReceiveInfo: '',
        reviewStatus: 1,
        refuseReason: '',
      },
      rules: {
        refuseReason: [{ required: true, message: '请选择' }]
      },
      disabled: false,
      title: '',
      type: 'edit',
    }
  },
  mounted() {
    this.getReasonList();
  },
  methods: {
    // 拒绝原因
    getReasonList() {
      this.$axios({
        method: 'get',
        url: this.$api.order.modifyReceiveRefuseList,
      }).then((res) => {
        if (res.code !== 0) return;
        this.rejectList = res.data;
      }).catch(err => {
        console.log(err);
      });
    },
    // 审核
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        this.$axios({
          method: 'post',
          url: this.$api.order.modifyReceiveReview,
          data: {
            reviewStatus: this.ruleForm.reviewStatus,
            refuseReason: this.ruleForm.refuseReason,
            id: this.ruleForm.id,
          }
        }).then((res) => {
          if (res.code !== 0) return this.$message.error(res);
          this.$message.success('修改成功');
          this.close();
          this.$emit('refreshList');
        }).catch(err=>{
          console.log(err);
        });
      })
    },
    close() {
      this.visible = false;
    },
    openHandle(item) {
      this.visible = true;
      this.disabled = false;
      this.ruleForm = Object.assign({}, item, {reviewStatus: 1});
      this.title = '修改收货地址';
      this.type = 'edit';
    },
    openLook(item) {
      this.visible = true;
      this.disabled = true;
      this.ruleForm = Object.assign({}, item);
      this.title = '查看改地址申请';
      this.type = 'look';
    },
  }
}
</script>
